<template>
    <ContentWrapper>
        <breadcrumb/>
        <div class="container">
            <OrderBrowser class="animated-fast fadeInUp"></OrderBrowser>
        </div>
    </ContentWrapper>
    </template>
<script>

    import OrderBrowser from '@/modules/Shop/components/OrderBrowser.vue';

    export default {
        components: {
            OrderBrowser
        },
        data() {
            return {

            }
        },
        beforeCreate() {

        },
        methods: {


        },
    }
</script>