<template>
    <div class="card card-data-browser card-gray">
        <div class="card-header">
            <i class="card-title-icon" :class="icon"></i> {{ title }}

            <nav class="float-right">
                <ul class="pagination mb-0">
                    <li class="page-item"><span class="page-link" @click="prevPage"><i class="fad fa-arrow-left"></i></span></li>
                    <li class="page-item" :class="{active: page === state.page}" v-for="page in state.pages"><span class="page-link">{{page}}</span></li>
                    <li class="page-item"><span class="page-link" @click="nextPage"><i class="fad fa-arrow-right"></i></span></li>
                </ul>
            </nav>

        </div>
        <div class="card-body p-0" :class="loading ? 'whirl' : ''">
            <div v-show="state.data.length" class="table-wrapper">
                <table class="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>{{ $t('wallet.component.orders.label') }}</th>
                        <th>{{ $t('wallet.component.orders.amount') }}</th>
                        <th>{{ $t('wallet.component.orders.status') }}</th>
                        <th>{{ $t('wallet.component.orders.created_at') }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in state.data">
                        <td>{{ item.label }}</td>
                        <td>{{ item.amount | currency }} EUR</td>
                        <td>{{ $t('wallet.component.orders.statuses.' +  item.status) }}</td>
                        <td class="text-right">{{ item.created_at | moment('utc', 'L')}} <span class="d-inline-block" style="width: 7em">{{ item.created_at | moment('utc', 'LTS')}}</span></td>
                        <td class="p-0 pt-1 text-center"><b-btn :to="{name: 'Order', params: {order_label: item.label}}" variant="link"><i class="fad fa-eye text-secondary" style="font-size: 1.3em"></i></b-btn></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-show="!state.data.length && !loading">
                <div class="m-3 alert alert-primary">
                    {{ $t('wallet.component.orders.empty') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableDataBrowser from '@/modules/Wallet/components/TableDataBrowser.vue';

    export default {
        extends: TableDataBrowser,
        data() {
            return {
                title: this.$t('wallet.component.orders.heading'),
                icon: 'fad fa-book',
                types: [],
                selectedType: undefined,
                endpoint: '/shop/orders',
            }
        },
        methods: {
            init: function () {
                this.refresh();
            },
            setType: function () {
                if (this.selectedType === 'undefined') {
                    delete this.endpointParams.type;
                } else {
                    this.endpointParams.type = this.selectedType;
                }

                this.state.meta.current_page = 1;

                this.refresh();
            }
        }
    }
</script>
